<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row mt-4">
			<div class="col-12 d-flex">
				<div class="h2 text-muted py-3">
					{{ translate('order_successfully_placed') }}
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<ul class="list-group w-100">
					<li class="list-group-item">
						<div class="row">
							<div
								id="content"
								class="col-sm-12 text-center"
								style="margin: 30px 0 60px;">
								<i class="fas fa-check-circle fa-10x success-icon" />
								<div class="success-message margin-20">
									<h3
										class="mt-4"
										v-html="translate('checkout_success_subtittle')" />
									<hr>
									<p v-html="translate('checkout_success_generic_body', {support:companyEmail, support1:companyEmail})" />
								</div>
								<div class="clearfix text-center">
									<div class="container">
										<div class="col-xs-12">
											<div class="text-center">
												<template v-if="$user.auth()">
													<b-button
														variant="primary"
														style="width: 200px"
														:class="!['xs'].includes(windowWidth) ? 'mr-4' : 'mb-2'"
														@click="$router.push({ name: 'Home' })">
														{{ translate('go_to_dashboard') }}
													</b-button>
													<b-button
														variant="primary"
														style="width: 200px"
														@click="$router.push({ name: 'Store' })">
														{{ translate('go_to_store') }}
													</b-button>
												</template>
												<template v-else>
													<b-button
														variant="primary"
														style="width: 200px"
														:class="!['xs'].includes(windowWidth) ? 'mr-4' : 'mb-2'"
														@click="$router.push({ name: getRedirectNameByReplicated('Store') })">
														{{ translate('go_to_store') }}
													</b-button>
													<b-button
														variant="primary"
														style="width: 200px"
														@click="$router.push({ name: 'Login' })">
														{{ translate('go_to_login') }}
													</b-button>
												</template>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-4 ml-auto my-4" />
		</div>
	</div>
</template>

<script>
import { Purchase, Validations } from '@/translations';
import DocumentTitle from '@/mixins/DocumentTitle';
import WindowSizes from '@/mixins/WindowSizes';
import { GOVERNMENT_DOC_COUNTRIES } from '@/settings/Country';
import AgencySite from '@/mixins/AgencySite';

export default {
	name: 'RedirectedPurchaseCreditCardSuccess',
	messages: [Purchase, Validations],
	mixins: [DocumentTitle, WindowSizes, AgencySite],
	data() {
		return {
			companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
			alert: new this.$Alert(),
		};
	},
	mounted() {
		const giftCountry = localStorage.getItem('gift_country');

		if (giftCountry) {
			localStorage.removeItem('gift_country');
		}

		const country = giftCountry ?? this.country;
		if (GOVERNMENT_DOC_COUNTRIES.includes(country)) {
			const alertConfig = {
				config: {
					icon: 'info',
					showCancelButton: false,
					isHtml: true,
				},
			};
			this.alert.confirmation(this.translate('government_doc_title'), this.translate('government_doc_text'), alertConfig, true);
		}
		const replicatedPage = this.$replicated.replicatedPage();
		const normalCustomCart = replicatedPage === 'checkout';
		const customCartExists = localStorage.getItem('custom_cart_uuid') || false;
		if (normalCustomCart && customCartExists) {
			// If replicated page is 'store' (no discounts store) or 'checkout', the custom cart id is deleted and users stays in the same replicated site
			localStorage.removeItem('custom_cart_uuid');
		}
	},
};
</script>
<style scoped>
.success-icon::before {
	color: #63D13E !important;
}
</style>
